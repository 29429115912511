.bg {
     background: #ffffff;
     color: #000000;
     font-size: 16px;
     font-weight: 400;
     font-family: "Work Sans", sans-serif;
}

a,
a:hover {
     text-decoration: none;
     color: #000000;
}

.cs-btn {
     background-color: #080101;
     color: #ffffff;
     font-family: "Open Sans", sans-serif;
     text-transform: uppercase;
     border-radius: 30px;
     padding: 18px 50px;
     display: inline-block;
     font-size: 20px;
     font-weight: bold;
     margin-top: 35px;
     letter-spacing: 3px;
     transition: all 0.5s ease;
}

.h2 {
     font-size: 24px;
}

.cs-btn:hover {
     color: #ffffff;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
     color: #000000;
}

/* Date Time */
.appDateTime {
     width: 768px;
     margin: 20px auto 0;
     background: #ffffff;
     overflow: hidden;
     border-radius: 6px;
}

.date-time-slot {
     display: flex;
     padding: 20px 40px;
}

.date-time-slot a,
.date-time-slot a.inactive {
     display: block;
     border: 1px solid #e3e3e3;
     border-radius: 6px;
     padding: 10px 10px;
     width: 100%;
     text-align: center;
     transition: all 0.5s ease;
     cursor: pointer;
     background: #ffffff;
     color: #000000;
}

.date-time-slot a.active,
.date-time-slot a.active:hover {
     border-color: #000000;
     background: #000000;
     color: #ffffff;
}

.slot-time-item.active,
.slot-time-item.active:hover {
     border-color: mediumvioletred;
     background: mediumvioletred;
     color: #ffffff;
}

.date-time-slot a:hover {
     background: #f4f4f4;
}

.date-time-slot a .slot-date {
     display: block;
     font-weight: 600;
     font-size: 24px;
}

.date-time-slot a .slot-day {
     display: block;
     margin-bottom: 0;
     font-size: 14px;
     opacity: 0.6;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
     font-size: 22px;
     font-weight: bold;
}

.date-time-slotSec {
     padding: 20px 40px;
     height: 360px;
     overflow-y: scroll;
     overflow-x: hidden;
}

.slot-time-carousel {
     display: flex;
     flex-wrap: wrap;
}

.slot-time-item {
     display: inline-block;
     border: 1px solid #e3e3e3;
     border-radius: 6px;
     padding: 20px 0;
     font-size: 20px;
     transition: all 0.3s ease;
     text-align: center;
     width: 127px;
     margin: 10px 5px;
     cursor: pointer;
}

.slot-time-item:nth-child(6) {
     margin-right: 0;
}

.slot-time-item:hover {
     color: #f9528f;
     transform: scale(1.01);
}

.swiper-slide {
     width: 100px !important;
}

.tab-container {
     display: none;
}

.tab-container.active {
     display: block;
}

.tab-pane {
     padding-bottom: 40px;
}

/* Loader */
.loader-gif {
     position: relative;
     width: 100%;
     float: left;
     height: 310px;
 }

.lds-ellipsis {
     display: inline-block;
     height: 16px;
     position: absolute;
     left: 0;
     right: 0;
     top: 45px;
     width: 76px;
     text-align: center;
     margin: auto;
     z-index: 9;
     background: #ffffff;
     border-radius: 50px;
}
.lds-ellipsis div {
     position: absolute;
     top: 1px;
     width: 13px;
     height: 13px;
     border-radius: 50%;
     background: #000000;
     animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
     left: 8px;
     animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
     left: 8px;
     animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
     left: 32px;
     animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
     left: 56px;
     animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
     0% {
          transform: scale(0);
     }
     100% {
          transform: scale(1);
     }
}
@keyframes lds-ellipsis3 {
     0% {
          transform: scale(1);
     }
     100% {
          transform: scale(0);
     }
}
@keyframes lds-ellipsis2 {
     0% {
          transform: translate(0, 0);
     }
     100% {
          transform: translate(24px, 0);
     }
}

.cart-col-list,
.payment-container {
     flex: 1;
}

.tip-sec {
     border-top: 1px solid gainsboro;
     padding-top: 20px;
     margin-top: 20px;
}

@media (max-width: 667px) {
     .date-time-slotSec {
          padding: 20px 20px;
      }
}
