#landing-page {
     .main {
          background-color: white;
          background-image: url("/assets/img/bg.png");
          background-position: top;
          background-repeat: no-repeat;
          background-size: contain;
          display: flex;
          justify-content: center;
          height: 100%;
          width: 100%;
          overflow: hidden;
          margin: 0;
          padding: 0;
          border-bottom-left-radius: 40px;
          border-bottom-right-radius: 40px;
     }
}
