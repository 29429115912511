.main {
     display: flex;
     justify-content: center;
     overflow: hidden;
     height: 100%;
     margin: 0 20px;
     padding: 0;
     background-color: #ffffff;
     overflow-y: auto;
}

.otp-form:after {
     content: "";
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.4);
     z-index: -1;
}

.otp-digit {
     width: 60px;
     height: 70px;
     margin: 0;
     padding: 0;
     text-align: center;
     font-size: 30px;
     border: 1px solid #cccccc;
     border-radius: 4px;
}

.error {
     border-color: #f00 !important;
}

.error-msg {
     color: #f00;
     text-align: center;
     margin-top: 10px;
     font-size: 24px;
}
