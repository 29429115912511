.transparent {
     background: transparent;
}

.panel {
     width: 100%;
     margin: 10px 5px 10px 15px;
     padding: 0;
     border-radius: 5px;
     box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.panel.selected {
     background-color: lightskyblue;
}

.slide-btn {
     border: 0.2px solid gainsboro;
     border-radius: 10px;
     padding: 0;
     font-size: small;
     padding: 5px 15px;
     margin: 5px 0;
}

.amount-title {
     font-size: 11px;
     color: gray;
}

.amount {
     font-size: 13px;
}

.red {
     color: red;
}

.green {
     color: green;
}

.blue {
     color: royalblue;
}

.total {
     font-size: 20px;
     font-weight: bold;
     color: blue;
     margin-top: 24px;
     display: inline-block;
}

.points {
     color: goldenrod;
     font-size: 13px;
     margin: 0;
}

.date {
     color: gray;
     font-size: 13px;
}

.service {
     height: 100px;
}

.vl {
     border-left: 1px solid gainsboro;
     height: 100%;
     width: 1px;
     position: absolute;
}

.serviceDesc {
     font-weight: bold;
     font-size: 20px !important;
}

.paymentDesc {
     color: gray;
     font-size: 14px;
     padding-top: 5px;
}

.divider {
     position: relative;
     float: left;
     width: 1%;
}

-webkit-scrollbar {
     display: none;
}

.company {
     font-size: 17px;
    font-weight: 600;
    display: block;
    text-align: center;
    padding-bottom: 10px;
}

.payment-amount {
     font-size: x-large;
     font-weight: bold;
}

.credit-card {
     padding-top: 10px;
     height: 48px;
     border-radius: 5px;
     background-color: purple;
     color: white;
     font-size: 24px;
     font-weight: 500;
}

.cards {
     text-align: center;
     margin: 5px 0 10px 0;
     width: 100%;
}

.text-xsmall {
     font-size: x-small;
}

.card-footer {
     display: flex;
     justify-content: space-between;
}

.header-md::after {
     display: none;
     }

     .dateTime span {
     display: block;
     }

     .dateTime p {
     margin-bottom: 10px;
     padding: 0;
     }

     .pointsTag {
     padding: 0 20px;
     align-self: end;
     }

     .pointsTag .points {
     font-size: 18px;
     }