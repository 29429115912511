#login-page,
#signup-page,
#support-page,
#card-form,
#landing-page {
  .login-logo {
    padding: 20px 0;
    min-height: 220px;
    text-align: center;
  }

  .login-logo img {
    max-width: 150px;
  }

  .list {
    margin-bottom: 10px;
  }

  .transparent {
    background-color: transparent;
  }

  .frame {
    background-color: transparent;
    margin-left: -10px;
    padding: 0px 35px 10px 0px;
    height: 160px;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-bottom-right-radius: 80px;
    border-top-right-radius: 80px;
  }

  .loginbutton {
    margin-top: 40px;
    margin-right: 0;
    margin-left: -20px;
    padding: 0;
    width: 75px;
    height: 75px;
  }

  .m-l-10 {
    margin-left: 10px;
  }

  .company {
    font-size: 14px;
    font-weight: bold;
  }

  .category {
    font-size: small;
  }

  .p-r-5 {
    padding-right: 10px;
  }

  .text-xsmall {
    font-size: x-small;
  }

  .scrollable-list {
    display: block;
    margin: 0;
    height: 45vh;
    overflow-y: auto;
  }

  .title {
    font-size: large;
    font-weight: bold;
  }

  .login-text {
    padding-left: 10px;
    font-size: small;
  }
}
