ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.receipt-container {
    background-color: #ffffff;
    width: 320px;
    margin: 20px auto 50px;
    box-shadow: 0 4px 20px #e3e3e3;
}

.receipt-header {
    background-color: #769f37;
}

.receipt-header,
.receipt-footer {
    width: 100%;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
}

.receipt-header h2 {
    color: #ffffff;
    padding: 0 20px;
}

.receipt-header p {
    font-size: 13px;
    margin-top: 0;
}

.receipt-body {
    width: 100%;
    box-sizing: border-box;
}

.receipt-body h4 {
    margin-bottom: 0;
    text-align: center;
    font-size: 12px;
}

.invoice-no {
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 10px;
    text-align: center;
}

.receipt-body ul {
    padding: 10px 30px;
}

.receipt-body .receipt-items {
    border-top: 1px dotted #cccccc;
    text-align: left;
}

.receipt-body .receipt-items .line-item {
    padding: 6px 0;
}

.receipt-body .receipt-items .line-item .label {
    display: flex;
}

.flex {
    display: flex;
}

.flex1 {
    flex: 1;
}

.flex3 {
    flex: 3;
}

.receipt-body .receipt-items .line-item .price {
    text-align: right;
    font-weight: 600;
}

.receipt-body .section-header {
    color: #999999;
    font-size: 14px;
}

.total-items {
    font-size: 24px;
    color: #4caf50;
}

.subtotal-items {
    font-size: 16px;
    color: gray;
}

.tender-types {
    font-size: 13px;
    border-top: 1px dotted #cccccc;
}

.tender-amount {
    font-size: 16px;
    color: red;
}

.tender-types img {
    width: 48px;
    margin-right: 10px;
}

.sm-text p {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #666666;
}

.receipt-footer {
    border-top: 1px dotted #cccccc;
}

.receipt-footer p {
    margin: 0 0 10px;
}

.receipt-footer a {
    color: #000000;
    font-size: 13px;
    text-decoration: none;
}

.receipt-footer a:hover {
    color: #4caf50;
}
