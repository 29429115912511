ion-modal {
	ion-backdrop {
		@media not all and (min-height: 600px) and (min-width: 768px) {
			display: unset;
		}
	}
}

.stripe-element .group-title {
	font-weight: bold;
}

.stripe-element .group-item {
	font-size: 14px;
	font-weight: normal;
}

.stripe-element input {
	font-size: 14px;
}

.stripe-element button {
	white-space: nowrap;
	border: 0;
	outline: 0;
	display: inline-block;
	height: 40px;
	line-height: 40px;
	padding: 0 14px;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	color: #fff;
	border-radius: 4px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	background-color: green;
	text-decoration: none;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	margin-top: 10px;
	width: 100%;
}

.stripe-element button:hover {
	color: #fff;
	cursor: pointer;
	background-color: #7795f8;
	transform: translateY(-1px);
	box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.StripeElement {
	display: block;
	margin: 10px 0 20px 0;
	max-width: 500px;
	padding: 10px 14px;
	font-size: 1em;
	font-family: "Source Code Pro", monospace;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border: 0;
	outline: 0;
	border-radius: 4px;
	background: white;
}

.StripeElement.input::placeholder {
	color: #aab7c4;
}

input:focus,
.StripeElement--focus {
	box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
	padding: 0;
}

.StripeElement.PaymentRequestButton {
	height: 40px;
}

.payment-container {
	background-color: #f9f9f9;
	overflow-x: auto;
	text-align: center;
	width: auto;
	height: 430px;
	padding: 5px;
	margin-left: 40px;
}

.payment-item {
	background-color: #ffffff;
	border: 1px solid gainsboro;
	border-radius: 5px;
	margin: 5px;
	width: 75px !important;
	height: 75px !important;
}

.payment-item.selected {
	background-color: lightskyblue;
	width: 75px !important;
	height: 75px !important;
}

.payment-item-content {
	width: 75px !important;
	height: 75px !important;
	font-size: 16px;
	margin: 0;
	padding: 0 5px;
	display: flex;
	justify-content: center;
}
.payment-cancel-policy-content {
	font-size: 10px;
	text-align: left;
	padding: 10px;
	margin: 10px;
	color: #666666;
	background: #f4f4f4;
 }
 
 .payment-cancel-policy-content div:first-child {
	font-weight: 600;
	margin-bottom: 6px;
 }

.pm {
	background-color: black;
	width: 75px !important;
	height: 75px !important;
	margin-top: 5px;
	margin-bottom: 5px;
}

.apple-pay {
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75px !important;
	height: 75px !important;
	padding: 0;
	margin: 0;
}

.google-pay {
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75px !important;
	height: 75px !important;
	padding: 0;
	margin: 0;
}

.apple-pay-img {
	height: 22px;
	display: inline-flex;
	align-items: center;
}

.apple-pay-text {
	color: white;
}

.google-pay-img {
	height: 22px;
	display: inline-flex;
	align-items: center;
}

.google-pay-text {
	color: white;
}
hr {
     margin-top: 0;
     margin-bottom: 0;
}
.prod-list-item {
     width: 100%;
 }

 .pk-btn {
     border-radius: 44px;
     padding: 0 60px;
     display: block;
     margin: auto;
     color: white;
     background-color: black;
     height: 50px;
     font-size: 20px;
	width: 100%;
 }

 .service-list-items {
	max-height: 240px;
	overflow: auto;
	margin-bottom: 20px;
 }

 .tab-content .tab-pane {
	padding-bottom: 20px;
 }