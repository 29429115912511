.main-tbl {
	font-family: "Open Sans", sans-serif;
	font-size: 10px;
	font-weight: 600;
	width: 100%;
}

table {
	border-collapse: collapse;
	width: 100%;
}

.tbl td {
	border: 1px solid #605c5c;
	padding: 0px;
	vertical-align: top;
}

.p-a-0 {
	padding: 0 !important;
}

.b-a-0 {
	border: 0px solid !important;
}

.b-t-0 {
	border-top: 0px solid !important;
}

.b-l-0 {
	border-left: 0px solid !important;
}

.b-r-0 {
	border-right: 0px solid !important;
}

.b-b-0 {
	border-bottom: 0px solid !important;
}

.address-box {
	width: 90%;
	height: 80px;
}

input,
textarea {
	border: 1px solid #cccccc;
	resize: none;
	font-family: "Open Sans", sans-serif;
	font-size: 12px;
}

.b-a-0-tbl,
.b-a-0-tbl td {
	border: 0;
}

.p-a-1-tbl td {
	padding: 5px;
}

h2 {
	font-weight: 800;
	font-size: 22px;
}

p {
	padding: 0 10px;
}

th {
	background: #605c5c;
	font-weight: 600;
	color: #ffffff !important;
}

a {
	text-decoration: none;
}

.blue-text,
.blue-text a,
th {
	color: #605c5c;
}

.m-a-0 {
	margin: 0;
}

.m-b-0 {
	margin-bottom: 0;
}

.m-t-0 {
	margin-top: 0;
}

.address-text {
	font-size: 12px;
}

.ft-text {
	font-size: 9px;
	font-weight: 400;
	margin: 0;
	padding: 0px 0;
	color: #ffffff;
}

.ft-text span:nth-child(2) {
	font-weight: 600;
	color: #ffea00;
}

.ft-text span:nth-child(3) {
	font-weight: 600;
	color: #00ffc0;
}

.infopoint-logo {
	position: relative;
}

.freight-btl td:nth-child(2) .cnt-box,
.freight-btl td:nth-child(3) .cnt-box {
	width: 30px;
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
}

.freight-btl td:nth-child(2) .cnt-box {
	width: 100px;
}

.prize-sec {
	font-size: 13px;
}

.amt-tag {
	font-size: 18px;
	font-weight: 600;
}

.net-amt span {
	display: inline-block;
	padding: 5px 20px;
	font-size: 20px;
	background-color: #79a13d;
	color: #ffffff;
}

.net-amt span strong {
	font-size: 32px;
	font-weight: 600;
}

@media print {
	input,
	textarea {
		border: 0;
	}
}

.infopoint-logo:after {
	content: "";
	width: 134px;
	height: 134px;
	display: inline-block;
	position: absolute;
	top: 30%;
	left: 40%;
	opacity: 0.2;
}
