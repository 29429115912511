ul {
     list-style: none;
     margin: 0;
     padding: 0;
}

ion-grid {
     list-style: none;
     margin: 0;
     padding: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
     font-family: inherit;
     font-weight: 500;
     line-height: 1.1;
     color: inherit;
     margin: 0;
}

p {
     margin: 0;
}

.bg {
     background: #ffffff;
     color: #000000;
     font-size: 16px;
     font-weight: 400;
     font-family: "Work Sans", sans-serif;
}

.cart-items {
     width: 320px;
     height: 100%;
     background-color: #ffffff;
     border-radius: 6px;
     border-bottom-left-radius: 0;
     border-bottom-right-radius: 0;
     margin: 50px auto 0;
     position: relative;
     padding: 15px;
}

.cart-items::before {
     content: "";
     position: absolute;
     left: 20px;
     bottom: -14px;
     right: 0px;
     background-repeat: repeat-x;
     height: 10px;
     background-size: 40px 23px;
     background-image: radial-gradient(circle at 10px -5px, rgb(242, 242, 247) 12px, rgba(255, 255, 255, 0) 13px);
}

.cart-items::before {
     background-image: radial-gradient(circle at 10px -5px, rgb(255, 255, 255) 12px, rgba(255, 255, 255, 0) 13px);
}

.cart-items::after {
     content: "";
     position: absolute;
     left: 0px;
     bottom: -15px;
     right: 0px;
     background-repeat: repeat-x;
     height: 15px;
     background-size: 40px 8px;
     background-image: radial-gradient(circle at 10px 17px, rgba(255, 255, 255, 0) 13px, rgb(242, 242, 247) 14px);
}

.cart-items::after {
     background-image: radial-gradient(circle at 10px 17px, rgba(255, 255, 255, 0) 13px, rgb(255, 255, 255) 14px);
}

.flex {
     display: flex;
}

.prod-title {
     font-size: 20px;
     padding-right: 30px;
}

.flex-2 {
     flex: 2;
}

.current-price {
     font-size: 26px;
     display: block;
     text-align: right;
}

.old-price {
     display: block;
     font-size: 20px;
     text-decoration: line-through;
     opacity: 0.6;
     text-align: center;
}

.prod-date {
     font-size: 14px;
     opacity: 0.7;
     margin-top: 5px;
     margin-bottom: 0;
}

.total-services {
     font-size: 12px;
     margin: 0;
     text-align: center;
}

.buynow {
     font-size: large;
     font-family: "Fjalla One", sans-serif;
     font-weight: 400;
     font-style: normal;
     color: #fff;
     background: #000;
     border-radius: 48px;
     padding: 11px 35px;
     display: inline-block;
     margin: 20px 0 0 0;
     cursor: pointer;
     border: 0;
}

.text-center {
     text-align: center;
}

.disclaimer-text {
     top: 10px;
     font-size: 12px;
     position: relative;
     text-align: center;
}

.mt-2 {
     margin-top: 2rem;
}

.cart-footer .prod-list li {
     border-bottom: 0;
     border-top: 1px dashed #ccc;
     padding-top: 15px;
}

.cart-footer .prod-list ion-row {
     border-bottom: 0;
     border-top: 1px dashed #ccc;
     padding-top: 15px;
}

.prod-list {
     max-height: 300px;
     background-color: #ffffff;
     border-radius: 6px;
     overflow: auto;
}

.prod-list li {
     padding: 5px 0;
     border-bottom: 1px dotted #cccccc;
     transition: all 0.5s ease;
}

.prod-list ion-row {
     padding: 5px 0;
     border-bottom: 1px dotted #cccccc;
     transition: all 0.5s ease;
}

.bb-1 {
     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.header-sec {
     padding: 10px 0 30px 0;
     position: relative;
}

.header-sec h3 {
     font-size: 20px;
     font-weight: 600;
}

.header-sec p {
     opacity: 0.6;
     font-size: 14px;
     margin: 10px 0;
}

.prof-sec {
     width: 80px;
     height: 84px;
     margin: auto;
     border-radius: 6px;
     overflow: hidden;
     background-color: #f4f4f4;
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     z-index: 1;
     border: 2px solid rgb(255, 255, 255);
     box-shadow: rgb(16 25 40 / 8%) 0px 8px 16px;
     transform: translateX(0%) translateY(-90px);
}

@media (max-width: 667px) {
     .cart-items {
          width: 100%;
     }

     .cart-col-sec {
          display: block;

     }

     .payment-container {
          margin: 40px 0 0 0;
     }
}