.pass {
     padding: 0;
     margin: 0 10px 20px 10px;
     height: auto;
     border: 1px solid gainsboro;
}

.pass .title {
     font-weight: bold;
     font-size: medium;
     color: white;
}

.pass .subtitle {
     font-size: small;
     color: white;
}

.pass .ad {
     background-color: white;
     border-radius: 5px;
     margin: 5px 0;
     height: 220px;
     width: 100%;
}

.pass .btn {
     display: block;
     text-align: center;
     height: 100%;
     background-color: white;
     border-radius: 5px;
     width: 100%;
     margin: 0;
     padding: 0;
}

.pass .stats {
     display: block;
     font-size: 11px;
     text-align: left;
     color: white;
}

.filter-btn {
     width: 70px;
     height: 30px;
     font-size: small;
     background-color: white;
     color: black;
     border: 1px solid gainsboro;
     border-radius: 50px;
     position: relative;
     float: left;
     margin: 0 0 10px 10px;
     padding-top: 5px;
}

.filter-btn-sel {
     width: 70px;
     height: 30px;
     font-size: small;
     background-color: mediumvioletred;
     border-radius: 50px;
     padding-top: 5px;
     margin: 0 0 10px 10px;
     color: white;
     position: relative;
     float: left;
     font-weight: bold;
}

.map-canvas {
     position: absolute;
     height: 100%;
     width: 100%;
     background-color: transparent;
     opacity: 0;
     transition: opacity 250ms ease-in;
}

.show-map {
     opacity: 1;
}

ion-slides {
     height: 100%;
}

.swiper-pagination-bullet {
     width: 10px;
     height: 10px;
     text-align: center;
     line-height: 20px;
     font-size: 12px;
     color: #000;
     opacity: 1;
     border: solid 1px black;
     background: transparent;
}

.swiper-pagination-bullet-active {
     color: #fff;
     border: none;
     background: #007aff;
}

.absolute {
     position: absolute;
}

.special-offer {
     height: 60px;
     top: 0;
     right: 0;
     margin-right: 35px;
     margin-top: -6px;
}

.hello {
     top: 0;
     left: 0;
     margin-top: 20px;
     margin-left: 50px;
     font-size: 14px;
     font-style: italic;
     color: black;
}

.line1 {
     top: 0;
     left: 0;
     margin-top: 45px;
     margin-left: 65px;
     font-size: 16px;
     font-weight: bold;
}

.line2 {
     top: 0;
     left: 0;
     margin-top: 68px;
     margin-left: 90px;
     font-size: 12px;
}

.line3 {
     top: 0;
     left: 0;
     margin-top: 90px;
     margin-left: 65px;
     font-size: 16px;
     font-weight: bold;
}

.line4 {
     top: 0;
     left: 0;
     margin-top: 113px;
     margin-left: 90px;
     font-size: 12px;
}

a {
     text-decoration: underline;
}

ion-content {
     --background: transparent !important;
}

.sc-ion-modal-md-h {
     --background: none;
}
.sc-ion-modal-ios-h {
     --background: none;
}

.container {
     border-bottom: 1px solid gray;
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
     padding: 5px;
     background: #f1f3f5;
     position: absolute;
     height: auto !important;
     width: 100%;
     bottom: 0;
}

.component {
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
     padding: 5px;
     // height: 100% !important;
     width: 100%;
}

.title {
     font-weight: bold;
     font-size: medium;
     color: black;
}

.subtitle {
     font-size: small;
     color: gray;
}

.map {
     margin: 0;
     padding: 0;
     height: 240px;
     width: 100%;
}

.reservation {
     margin: 0;
     padding: 0;
     height: 170px;
     width: 100%;
     border-radius: 5px;
}

.reservation .header {
     background: gray;
     margin: 0;
     padding: 5px;
}

.reservation .header .title {
     color: white;
     font-size: medium;
     font-weight: bold;
}

.reservation .header .subtitle {
     color: white;
     font-size: small;
     word-wrap: break-word;
}

.res-panel {
     display: flex;
     overflow-x: auto;
     -ms-overflow-style: none; /* IE and Edge */
     scrollbar-width: none; /* Firefox */
}

.res-panel::-webkit-scrollbar {
     display: none;
}

.res-date {
     margin: 5px;
     padding: 5px 0;
     border-radius: 20px;
     border: 1px solid gainsboro;
     width: 70px;
     text-align: center;
     color: black;
     flex: 0 0 auto;
     font-size: 12px;
}

.res-time {
     margin: 10px 5px 0px 5px;
     padding: 5px;
     border-radius: 5px;
     border: 1px solid gainsboro;
     width: 70px;
     height: 50px;
     text-align: center;
     color: black;
     flex: 0 0 auto;
     font-size: 14px;
     display: inline-block;
     word-break: break-word;
     white-space: pre-line;
     overflow-wrap: break-word;
     -ms-word-break: break-word;
     word-break: break-word;
     -ms-hyphens: auto;
     -moz-hyphens: auto;
     -webkit-hyphens: auto;
     hyphens: auto;
}

.selected {
     background: mediumvioletred;
     color: white;
     font-weight: bold;
}

.unselected {
     background: white;
     color: black;
     font-weight: normal;
}

.m-r-10 {
     margin-right: 10px;
}

.bottom {
     position: absolute;
     bottom: 0;
}